import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from 'vue';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  CompetitionEventLookupCode: { input: any; output: any; }
  JSONObject: { input: { [prop: string]: any }; output: { [prop: string]: any }; }
  Timestamp: { input: number; output: number; }
};

export type Actor = Device | User;

export type Athlete = {
  __typename?: 'Athlete';
  category: Category;
  club?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  ijruId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type BatteryStatus = {
  __typename?: 'BatteryStatus';
  automatic: Scalars['Boolean']['output'];
  batteryLevel: Scalars['Int']['output'];
  charging?: Maybe<Scalars['Boolean']['output']>;
  updatedAt: Scalars['Timestamp']['output'];
};

export type BatteryStatusInput = {
  automatic: Scalars['Boolean']['input'];
  batteryLevel: Scalars['Int']['input'];
  charging?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Category = {
  __typename?: 'Category';
  competitionEventIds: Array<Scalars['CompetitionEventLookupCode']['output']>;
  entries: Array<Entry>;
  entry?: Maybe<Entry>;
  group: Group;
  id: Scalars['ID']['output'];
  judgeAssignments: Array<JudgeAssignment>;
  latestRankedResult?: Maybe<RankedResult>;
  name: Scalars['String']['output'];
  pagePrintConfig: Array<PagePrintConfig>;
  participants: Array<Participant>;
  rankedResult?: Maybe<RankedResult>;
  rankedResults: Array<RankedResult>;
  rulesId: Scalars['String']['output'];
  type: CategoryType;
};


export type CategoryEntriesArgs = {
  competitionEventId?: InputMaybe<Scalars['CompetitionEventLookupCode']['input']>;
};


export type CategoryEntryArgs = {
  entryId: Scalars['ID']['input'];
};


export type CategoryLatestRankedResultArgs = {
  competitionEventId: Scalars['CompetitionEventLookupCode']['input'];
  maxVisibility?: InputMaybe<ResultVersionType>;
};


export type CategoryRankedResultArgs = {
  resultId: Scalars['ID']['input'];
};


export type CategoryRankedResultsArgs = {
  beforeLockedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  competitionEventId?: InputMaybe<Scalars['CompetitionEventLookupCode']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  maxVisibility?: InputMaybe<ResultVersionType>;
};

export enum CategoryType {
  Individual = 'Individual',
  Team = 'Team'
}

export type CreateAthleteInput = {
  club?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  ijruId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateCategoryInput = {
  competitionEventIds?: InputMaybe<Array<Scalars['CompetitionEventLookupCode']['input']>>;
  name: Scalars['String']['input'];
  rulesId: Scalars['String']['input'];
  type: CategoryType;
};

export type CreateEntryInput = {
  competitionEventId: Scalars['CompetitionEventLookupCode']['input'];
  heat?: InputMaybe<Scalars['Int']['input']>;
  pool?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateGroupInput = {
  name: Scalars['String']['input'];
  resultVisibility?: InputMaybe<ResultVisibilityLevel>;
};

export type CreateJudgeAssignmentInput = {
  competitionEventId: Scalars['CompetitionEventLookupCode']['input'];
  judgeType: Scalars['String']['input'];
  options?: InputMaybe<Scalars['JSONObject']['input']>;
  pool?: InputMaybe<Scalars['Int']['input']>;
};

export type CreateJudgeInput = {
  ijruId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateMarkScoresheetInput = {
  options?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type CreateTallyScoresheetInput = {
  options?: InputMaybe<Scalars['JSONObject']['input']>;
  tally?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type CreateTeamInput = {
  club?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  members: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type Device = {
  __typename?: 'Device';
  battery?: Maybe<BatteryStatus>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  streamShares: Array<DeviceStreamShare>;
};

export type DeviceStreamJudgeInfo = {
  __typename?: 'DeviceStreamJudgeInfo';
  competitionEventId?: Maybe<Scalars['CompetitionEventLookupCode']['output']>;
  judgeType?: Maybe<Scalars['String']['output']>;
  rulesId?: Maybe<Scalars['String']['output']>;
};

export type DeviceStreamJudgeInfoInput = {
  competitionEventId: Scalars['CompetitionEventLookupCode']['input'];
  judgeType: Scalars['String']['input'];
  rulesId: Scalars['String']['input'];
};

export type DeviceStreamMarkEvent = {
  __typename?: 'DeviceStreamMarkEvent';
  device: Device;
  info: DeviceStreamJudgeInfo;
  mark: Scalars['JSONObject']['output'];
  sequence: Scalars['Int']['output'];
  tally: Scalars['JSONObject']['output'];
};

export type DeviceStreamShare = {
  __typename?: 'DeviceStreamShare';
  device: Device;
  expiresAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  status: DeviceStreamShareStatus;
  user: User;
};

export enum DeviceStreamShareStatus {
  Accepted = 'Accepted',
  Pending = 'Pending'
}

export type Entry = {
  __typename?: 'Entry';
  category: Category;
  competitionEventId: Scalars['CompetitionEventLookupCode']['output'];
  createdAt: Scalars['Timestamp']['output'];
  didNotSkipAt?: Maybe<Scalars['Timestamp']['output']>;
  heat?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  lockedAt?: Maybe<Scalars['Timestamp']['output']>;
  participant: Participant;
  pool?: Maybe<Scalars['Int']['output']>;
  scoresheet?: Maybe<Scoresheet>;
  scoresheets: Array<Scoresheet>;
};


export type EntryScoresheetArgs = {
  scoresheetId: Scalars['ID']['input'];
};


export type EntryScoresheetsArgs = {
  since?: InputMaybe<Scalars['Timestamp']['input']>;
};

export type Group = {
  __typename?: 'Group';
  admins: Array<User>;
  categories: Array<Category>;
  category?: Maybe<Category>;
  completedAt?: Maybe<Scalars['Timestamp']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  currentHeat?: Maybe<Scalars['Int']['output']>;
  deviceJudge: Judge;
  entries: Array<Entry>;
  entriesByHeat: Array<Entry>;
  entry?: Maybe<Entry>;
  id: Scalars['ID']['output'];
  judges: Array<Judge>;
  name: Scalars['String']['output'];
  resultVisibility?: Maybe<ResultVisibilityLevel>;
  viewers: Array<User>;
};


export type GroupCategoryArgs = {
  categoryId: Scalars['ID']['input'];
};


export type GroupEntriesByHeatArgs = {
  heat: Scalars['Int']['input'];
};


export type GroupEntryArgs = {
  entryId: Scalars['ID']['input'];
};

export type Judge = {
  __typename?: 'Judge';
  assignments: Array<JudgeAssignment>;
  device?: Maybe<Device>;
  group: Group;
  id: Scalars['ID']['output'];
  ijruId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};


export type JudgeAssignmentsArgs = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
};

export type JudgeAssignment = {
  __typename?: 'JudgeAssignment';
  category: Category;
  competitionEventId: Scalars['CompetitionEventLookupCode']['output'];
  id: Scalars['ID']['output'];
  judge: Judge;
  judgeType: Scalars['String']['output'];
  options?: Maybe<Scalars['JSONObject']['output']>;
  pool?: Maybe<Scalars['Int']['output']>;
};

export type MarkScoresheet = Scoresheet & {
  __typename?: 'MarkScoresheet';
  competitionEventId: Scalars['CompetitionEventLookupCode']['output'];
  completedAt?: Maybe<Scalars['Timestamp']['output']>;
  createdAt: Scalars['Timestamp']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  device: Device;
  entry: Entry;
  excludedAt?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  judge: Judge;
  judgeType: Scalars['String']['output'];
  marks: Array<Scalars['JSONObject']['output']>;
  openedAt?: Maybe<Array<Scalars['Timestamp']['output']>>;
  options?: Maybe<Scalars['JSONObject']['output']>;
  rulesId: Scalars['String']['output'];
  submittedAt?: Maybe<Scalars['Timestamp']['output']>;
  submitterProgramVersion?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Timestamp']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addDeviceStreamMark: DeviceStreamMarkEvent;
  addGroupAdmin: Group;
  addGroupViewer: Group;
  addStreamMark: StreamMarkEvent;
  createAthlete: Athlete;
  createCategory: Category;
  createDeviceStreamShare: DeviceStreamShare;
  createEntry: Entry;
  createGroup: Group;
  createJudge: Judge;
  createJudgeAssignment: JudgeAssignment;
  createMarkScoresheet: MarkScoresheet;
  createTallyScoresheet: TallyScoresheet;
  createTeam: Team;
  deleteCategory: Category;
  deleteDeviceStreamShare: DeviceStreamShare;
  deleteJudgeAssignment: JudgeAssignment;
  deleteParticipant: Participant;
  fillMarkScoresheet: MarkScoresheet;
  fillTallyScoresheet: TallyScoresheet;
  registerDevice: Scalars['String']['output'];
  registerUser: Scalars['String']['output'];
  releaseRankedResultVersion: RankedResult;
  removeGroupAdmin: Group;
  removeGroupViewer: Group;
  reorderEntry: Entry;
  requestDeviceStreamShare: DeviceStreamShare;
  setCurrentHeat: Group;
  setJudgeDevice: Judge;
  setPagePrintConfig: Category;
  setRankedResultVersion: RankedResult;
  setScoresheetExclusion: Scoresheet;
  setScoresheetOptions: Scoresheet;
  toggleEntryLock: Entry;
  toggleGroupComplete: Group;
  unsetJudgeDevice: Judge;
  updateAthlete: Athlete;
  updateCategory: Category;
  updateDeviceStatus: Device;
  updateGroup: Group;
  updateJudge: Judge;
  updateJudgeAssignment: JudgeAssignment;
  updateTeam: Team;
  updateUser: User;
};


export type MutationAddDeviceStreamMarkArgs = {
  info?: InputMaybe<DeviceStreamJudgeInfoInput>;
  mark: Scalars['JSONObject']['input'];
  tally: Scalars['JSONObject']['input'];
};


export type MutationAddGroupAdminArgs = {
  groupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationAddGroupViewerArgs = {
  groupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationAddStreamMarkArgs = {
  mark: Scalars['JSONObject']['input'];
  scoresheetId: Scalars['ID']['input'];
  tally: Scalars['JSONObject']['input'];
};


export type MutationCreateAthleteArgs = {
  categoryId: Scalars['ID']['input'];
  data: CreateAthleteInput;
};


export type MutationCreateCategoryArgs = {
  data: CreateCategoryInput;
  groupId: Scalars['ID']['input'];
};


export type MutationCreateDeviceStreamShareArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationCreateEntryArgs = {
  categoryId: Scalars['ID']['input'];
  data: CreateEntryInput;
  participantId: Scalars['ID']['input'];
};


export type MutationCreateGroupArgs = {
  data: CreateGroupInput;
};


export type MutationCreateJudgeArgs = {
  data: CreateJudgeInput;
  groupId: Scalars['ID']['input'];
};


export type MutationCreateJudgeAssignmentArgs = {
  categoryId: Scalars['ID']['input'];
  data: CreateJudgeAssignmentInput;
  judgeId: Scalars['ID']['input'];
};


export type MutationCreateMarkScoresheetArgs = {
  data: CreateMarkScoresheetInput;
  entryId: Scalars['ID']['input'];
  judgeId: Scalars['ID']['input'];
};


export type MutationCreateTallyScoresheetArgs = {
  data: CreateTallyScoresheetInput;
  entryId: Scalars['ID']['input'];
  judgeId: Scalars['ID']['input'];
};


export type MutationCreateTeamArgs = {
  categoryId: Scalars['ID']['input'];
  data: CreateTeamInput;
};


export type MutationDeleteCategoryArgs = {
  categoryId: Scalars['ID']['input'];
};


export type MutationDeleteDeviceStreamShareArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationDeleteJudgeAssignmentArgs = {
  judgeAssignmentId: Scalars['ID']['input'];
};


export type MutationDeleteParticipantArgs = {
  participantId: Scalars['ID']['input'];
};


export type MutationFillMarkScoresheetArgs = {
  completedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  marks?: InputMaybe<Array<Scalars['JSONObject']['input']>>;
  openedAt?: InputMaybe<Scalars['Timestamp']['input']>;
  programVersion?: InputMaybe<Scalars['String']['input']>;
  scoresheetId: Scalars['ID']['input'];
};


export type MutationFillTallyScoresheetArgs = {
  programVersion?: InputMaybe<Scalars['String']['input']>;
  scoresheetId: Scalars['ID']['input'];
  tally: Scalars['JSONObject']['input'];
};


export type MutationRegisterDeviceArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRegisterUserArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationReleaseRankedResultVersionArgs = {
  resultId: Scalars['ID']['input'];
};


export type MutationRemoveGroupAdminArgs = {
  groupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationRemoveGroupViewerArgs = {
  groupId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationReorderEntryArgs = {
  entryId: Scalars['ID']['input'];
  heat?: InputMaybe<Scalars['Int']['input']>;
  pool?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationRequestDeviceStreamShareArgs = {
  deviceId: Scalars['ID']['input'];
};


export type MutationSetCurrentHeatArgs = {
  groupId: Scalars['ID']['input'];
  heat: Scalars['Int']['input'];
};


export type MutationSetJudgeDeviceArgs = {
  deviceId: Scalars['ID']['input'];
  judgeId: Scalars['ID']['input'];
};


export type MutationSetPagePrintConfigArgs = {
  categoryId: Scalars['ID']['input'];
  competitionEventId: Scalars['CompetitionEventLookupCode']['input'];
  data: SetPagePrintConfigInput;
};


export type MutationSetRankedResultVersionArgs = {
  name: Scalars['String']['input'];
  resultId: Scalars['ID']['input'];
  type: ResultVersionType;
};


export type MutationSetScoresheetExclusionArgs = {
  exclude: Scalars['Boolean']['input'];
  scoresheetId: Scalars['ID']['input'];
};


export type MutationSetScoresheetOptionsArgs = {
  options: Scalars['JSONObject']['input'];
  scoresheetId: Scalars['ID']['input'];
};


export type MutationToggleEntryLockArgs = {
  didNotSkip?: InputMaybe<Scalars['Boolean']['input']>;
  entryId: Scalars['ID']['input'];
  lock: Scalars['Boolean']['input'];
};


export type MutationToggleGroupCompleteArgs = {
  completed: Scalars['Boolean']['input'];
  groupId: Scalars['ID']['input'];
};


export type MutationUnsetJudgeDeviceArgs = {
  judgeId: Scalars['ID']['input'];
};


export type MutationUpdateAthleteArgs = {
  data: UpdateAthleteInput;
  participantId: Scalars['ID']['input'];
};


export type MutationUpdateCategoryArgs = {
  categoryId: Scalars['ID']['input'];
  data: UpdateCategoryInput;
};


export type MutationUpdateDeviceStatusArgs = {
  batteryStatus: BatteryStatusInput;
};


export type MutationUpdateGroupArgs = {
  data: UpdateGroupInput;
  groupId: Scalars['ID']['input'];
};


export type MutationUpdateJudgeArgs = {
  data: UpdateJudgeInput;
  judgeId: Scalars['ID']['input'];
};


export type MutationUpdateJudgeAssignmentArgs = {
  data: UpdateJudgeAssignmentInput;
  judgeAssignmentId: Scalars['ID']['input'];
};


export type MutationUpdateTeamArgs = {
  data: UpdateTeamInput;
  participantId: Scalars['ID']['input'];
};


export type MutationUpdateUserArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type PagePrintConfig = {
  __typename?: 'PagePrintConfig';
  competitionEventId?: Maybe<Scalars['CompetitionEventLookupCode']['output']>;
  exclude?: Maybe<Scalars['Boolean']['output']>;
  zoom?: Maybe<Scalars['Float']['output']>;
};

export type Participant = Athlete | Team;

export type Query = {
  __typename?: 'Query';
  group?: Maybe<Group>;
  groups: Array<Group>;
  me?: Maybe<Actor>;
};


export type QueryGroupArgs = {
  groupId: Scalars['ID']['input'];
};


export type QueryGroupsArgs = {
  includeCompleted?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RankedResult = {
  __typename?: 'RankedResult';
  competitionEventId: Scalars['CompetitionEventLookupCode']['output'];
  id: Scalars['ID']['output'];
  maxEntryLockedAt: Scalars['Timestamp']['output'];
  results: Array<Scalars['JSONObject']['output']>;
  versionName?: Maybe<Scalars['String']['output']>;
  versionType: ResultVersionType;
};

export enum ResultVersionType {
  Private = 'Private',
  Public = 'Public',
  Temporary = 'Temporary'
}

export enum ResultVisibilityLevel {
  Live = 'Live',
  Private = 'Private',
  PublicVersions = 'PublicVersions'
}

export type Scoresheet = {
  competitionEventId: Scalars['CompetitionEventLookupCode']['output'];
  createdAt: Scalars['Timestamp']['output'];
  /** @deprecated use excludedAt */
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  entry: Entry;
  excludedAt?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  judge: Judge;
  judgeType: Scalars['String']['output'];
  options?: Maybe<Scalars['JSONObject']['output']>;
  rulesId: Scalars['String']['output'];
  submitterProgramVersion?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Timestamp']['output'];
};

export type SetPagePrintConfigInput = {
  exclude?: InputMaybe<Scalars['Boolean']['input']>;
  zoom?: InputMaybe<Scalars['Float']['input']>;
};

export type StreamMarkEvent = {
  __typename?: 'StreamMarkEvent';
  mark: Scalars['JSONObject']['output'];
  scoresheet: Scoresheet;
  sequence: Scalars['Int']['output'];
  tally: Scalars['JSONObject']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  deviceStreamMarkAdded: DeviceStreamMarkEvent;
  heatChanged: Scalars['Int']['output'];
  scoresheetChanged: Scalars['ID']['output'];
  streamMarkAdded: StreamMarkEvent;
};


export type SubscriptionDeviceStreamMarkAddedArgs = {
  deviceIds: Array<Scalars['ID']['input']>;
};


export type SubscriptionHeatChangedArgs = {
  groupId: Scalars['ID']['input'];
};


export type SubscriptionScoresheetChangedArgs = {
  entryIds: Array<Scalars['ID']['input']>;
};


export type SubscriptionStreamMarkAddedArgs = {
  scoresheetIds: Array<Scalars['ID']['input']>;
};

export type TallyScoresheet = Scoresheet & {
  __typename?: 'TallyScoresheet';
  competitionEventId: Scalars['CompetitionEventLookupCode']['output'];
  createdAt: Scalars['Timestamp']['output'];
  deletedAt?: Maybe<Scalars['Timestamp']['output']>;
  entry: Entry;
  excludedAt?: Maybe<Scalars['Timestamp']['output']>;
  id: Scalars['ID']['output'];
  judge: Judge;
  judgeType: Scalars['String']['output'];
  options?: Maybe<Scalars['JSONObject']['output']>;
  rulesId: Scalars['String']['output'];
  submitterProgramVersion?: Maybe<Scalars['String']['output']>;
  tally?: Maybe<Scalars['JSONObject']['output']>;
  updatedAt: Scalars['Timestamp']['output'];
};

export type Team = {
  __typename?: 'Team';
  category: Category;
  club?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  members: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type UpdateAthleteInput = {
  club?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  ijruId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCategoryInput = {
  competitionEventIds?: InputMaybe<Array<Scalars['CompetitionEventLookupCode']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGroupInput = {
  name: Scalars['String']['input'];
  resultVisibility?: InputMaybe<ResultVisibilityLevel>;
};

export type UpdateJudgeAssignmentInput = {
  options?: InputMaybe<Scalars['JSONObject']['input']>;
};

export type UpdateJudgeInput = {
  ijruId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTeamInput = {
  club?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  members?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  streamShares: Array<DeviceStreamShare>;
};

export type DeviceStreamShareFragment = { __typename?: 'DeviceStreamShare', id: string, status: DeviceStreamShareStatus, expiresAt: number, device: { __typename?: 'Device', id: string, name?: string | null, battery?: { __typename?: 'BatteryStatus', automatic: boolean, charging?: boolean | null, batteryLevel: number, updatedAt: number } | null } };

export type EntryFragment = { __typename?: 'Entry', id: string, competitionEventId: any, didNotSkipAt?: number | null, lockedAt?: number | null, heat?: number | null, pool?: number | null, category: { __typename?: 'Category', id: string, name: string, rulesId: string }, participant: { __typename?: 'Athlete', id: string, name: string, club?: string | null, country?: string | null, ijruId?: string | null } | { __typename?: 'Team', id: string, name: string, club?: string | null, country?: string | null, members: Array<string> } };

export type AthleteFragment = { __typename?: 'Athlete', id: string, name: string, club?: string | null, country?: string | null, ijruId?: string | null };

export type TeamFragment = { __typename?: 'Team', id: string, name: string, club?: string | null, country?: string | null, members: Array<string> };

export type RankedResultBaseFragment = { __typename?: 'RankedResult', id: string, versionName?: string | null, versionType: ResultVersionType, competitionEventId: any, maxEntryLockedAt: number, results: Array<{ [prop: string]: any }> };

export type ScoresheetBaseFragment_MarkScoresheet = { __typename?: 'MarkScoresheet', id: string, rulesId: string, judgeType: string, competitionEventId: any, createdAt: number, updatedAt: number, excludedAt?: number | null, options?: { [prop: string]: any } | null, completedAt?: number | null, submittedAt?: number | null, openedAt?: Array<number> | null, marks: Array<{ [prop: string]: any }>, judge: { __typename?: 'Judge', id: string, name: string } };

export type ScoresheetBaseFragment_TallyScoresheet = { __typename?: 'TallyScoresheet', id: string, rulesId: string, judgeType: string, competitionEventId: any, createdAt: number, updatedAt: number, excludedAt?: number | null, options?: { [prop: string]: any } | null, tally?: { [prop: string]: any } | null, judge: { __typename?: 'Judge', id: string } };

export type ScoresheetBaseFragment = ScoresheetBaseFragment_MarkScoresheet | ScoresheetBaseFragment_TallyScoresheet;

export type MarkScoresheetFragment = { __typename?: 'MarkScoresheet', completedAt?: number | null, submittedAt?: number | null, openedAt?: Array<number> | null, marks: Array<{ [prop: string]: any }>, judge: { __typename?: 'Judge', id: string, name: string } };

export type TallyScoresheetFragment = { __typename?: 'TallyScoresheet', tally?: { [prop: string]: any } | null };

export type RegisterUserMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type RegisterUserMutation = { __typename?: 'Mutation', registerUser: string };

export type RequestStreamShareMutationVariables = Exact<{
  deviceId: Scalars['ID']['input'];
}>;


export type RequestStreamShareMutation = { __typename?: 'Mutation', requestDeviceStreamShare: { __typename?: 'DeviceStreamShare', id: string, status: DeviceStreamShareStatus, expiresAt: number, device: { __typename?: 'Device', id: string, name?: string | null, battery?: { __typename?: 'BatteryStatus', automatic: boolean, charging?: boolean | null, batteryLevel: number, updatedAt: number } | null } } };

export type UpdateUserMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: string, name?: string | null } };

export type GroupEntriesQueryVariables = Exact<{
  groupId: Scalars['ID']['input'];
}>;


export type GroupEntriesQuery = { __typename?: 'Query', group?: { __typename?: 'Group', id: string, entries: Array<{ __typename?: 'Entry', id: string, competitionEventId: any, didNotSkipAt?: number | null, lockedAt?: number | null, heat?: number | null, pool?: number | null, category: { __typename?: 'Category', id: string, name: string, rulesId: string }, participant: { __typename?: 'Athlete', id: string, name: string, club?: string | null, country?: string | null, ijruId?: string | null } | { __typename?: 'Team', id: string, name: string, club?: string | null, country?: string | null, members: Array<string> } }> } | null };

export type GroupInfoQueryVariables = Exact<{
  groupId: Scalars['ID']['input'];
}>;


export type GroupInfoQuery = { __typename?: 'Query', group?: { __typename?: 'Group', id: string, name: string, currentHeat?: number | null } | null };

export type GroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GroupsQuery = { __typename?: 'Query', groups: Array<{ __typename?: 'Group', id: string, name: string, completedAt?: number | null }> };

export type HeatEntriesQueryVariables = Exact<{
  groupId: Scalars['ID']['input'];
  heat: Scalars['Int']['input'];
}>;


export type HeatEntriesQuery = { __typename?: 'Query', group?: { __typename?: 'Group', id: string, entriesByHeat: Array<{ __typename?: 'Entry', id: string, competitionEventId: any, didNotSkipAt?: number | null, lockedAt?: number | null, heat?: number | null, pool?: number | null, category: { __typename?: 'Category', id: string, name: string, rulesId: string }, participant: { __typename?: 'Athlete', id: string, name: string, club?: string | null, country?: string | null, ijruId?: string | null } | { __typename?: 'Team', id: string, name: string, club?: string | null, country?: string | null, members: Array<string> } }> } | null };

export type HeatEntriesScoresheetsQueryVariables = Exact<{
  groupId: Scalars['ID']['input'];
  heat: Scalars['Int']['input'];
}>;


export type HeatEntriesScoresheetsQuery = { __typename?: 'Query', group?: { __typename?: 'Group', id: string, entriesByHeat: Array<{ __typename?: 'Entry', id: string, competitionEventId: any, didNotSkipAt?: number | null, lockedAt?: number | null, heat?: number | null, pool?: number | null, scoresheets: Array<{ __typename?: 'MarkScoresheet', id: string, rulesId: string, judgeType: string, competitionEventId: any, createdAt: number, updatedAt: number, excludedAt?: number | null, options?: { [prop: string]: any } | null, completedAt?: number | null, submittedAt?: number | null, openedAt?: Array<number> | null, marks: Array<{ [prop: string]: any }>, judge: { __typename?: 'Judge', id: string, name: string } } | { __typename?: 'TallyScoresheet', id: string, rulesId: string, judgeType: string, competitionEventId: any, createdAt: number, updatedAt: number, excludedAt?: number | null, options?: { [prop: string]: any } | null, tally?: { [prop: string]: any } | null, judge: { __typename?: 'Judge', id: string } }>, category: { __typename?: 'Category', id: string, name: string, rulesId: string }, participant: { __typename?: 'Athlete', id: string, name: string, club?: string | null, country?: string | null, ijruId?: string | null } | { __typename?: 'Team', id: string, name: string, club?: string | null, country?: string | null, members: Array<string> } }> } | null };

export type LeaderboardQueryVariables = Exact<{
  groupId: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  maxVisibility?: InputMaybe<ResultVersionType>;
}>;


export type LeaderboardQuery = { __typename?: 'Query', group?: { __typename?: 'Group', id: string, name: string, currentHeat?: number | null, categories: Array<{ __typename?: 'Category', id: string, name: string, type: CategoryType, participants: Array<{ __typename?: 'Athlete', id: string, name: string, club?: string | null, country?: string | null, ijruId?: string | null } | { __typename?: 'Team', id: string, name: string, club?: string | null, country?: string | null, members: Array<string> }>, rankedResults: Array<{ __typename?: 'RankedResult', id: string, versionName?: string | null, versionType: ResultVersionType, competitionEventId: any, maxEntryLockedAt: number, results: Array<{ [prop: string]: any }> }> }> } | null };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'Device', id: string, name?: string | null } | { __typename?: 'User', id: string, name?: string | null } | null };

export type UserStreamSharesQueryVariables = Exact<{ [key: string]: never; }>;


export type UserStreamSharesQuery = { __typename?: 'Query', me?: { __typename?: 'Device', id: string } | { __typename?: 'User', id: string, streamShares: Array<{ __typename?: 'DeviceStreamShare', id: string, status: DeviceStreamShareStatus, expiresAt: number, device: { __typename?: 'Device', id: string, name?: string | null, battery?: { __typename?: 'BatteryStatus', automatic: boolean, charging?: boolean | null, batteryLevel: number, updatedAt: number } | null } }> } | null };

export type DeviceStreamMarkAddedSubscriptionVariables = Exact<{
  deviceIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type DeviceStreamMarkAddedSubscription = { __typename?: 'Subscription', deviceStreamMarkAdded: { __typename?: 'DeviceStreamMarkEvent', sequence: number, tally: { [prop: string]: any }, mark: { [prop: string]: any }, info: { __typename?: 'DeviceStreamJudgeInfo', competitionEventId?: any | null, rulesId?: string | null, judgeType?: string | null }, device: { __typename?: 'Device', id: string } } };

export type HeatChangedSubscriptionVariables = Exact<{
  groupId: Scalars['ID']['input'];
}>;


export type HeatChangedSubscription = { __typename?: 'Subscription', heatChanged: number };

export type ScoresheetChangedSubscriptionVariables = Exact<{
  entryIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type ScoresheetChangedSubscription = { __typename?: 'Subscription', scoresheetChanged: string };

export type StreamMarkAddedSubscriptionVariables = Exact<{
  scoresheetIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type StreamMarkAddedSubscription = { __typename?: 'Subscription', streamMarkAdded: { __typename?: 'StreamMarkEvent', sequence: number, tally: { [prop: string]: any }, mark: { [prop: string]: any }, scoresheet: { __typename?: 'MarkScoresheet', id: string } | { __typename?: 'TallyScoresheet', id: string } } };

export const DeviceStreamShareFragmentDoc = gql`
    fragment DeviceStreamShareFragment on DeviceStreamShare {
  id
  device {
    id
    name
    battery {
      automatic
      charging
      batteryLevel
      updatedAt
    }
  }
  status
  expiresAt
}
    `;
export const AthleteFragmentDoc = gql`
    fragment AthleteFragment on Athlete {
  id
  name
  club
  country
  ijruId
}
    `;
export const TeamFragmentDoc = gql`
    fragment TeamFragment on Team {
  id
  name
  club
  country
  members
}
    `;
export const EntryFragmentDoc = gql`
    fragment EntryFragment on Entry {
  id
  category {
    id
    name
    rulesId
  }
  participant {
    ...AthleteFragment
    ...TeamFragment
  }
  competitionEventId
  didNotSkipAt
  lockedAt
  heat
  pool
}
    ${AthleteFragmentDoc}
${TeamFragmentDoc}`;
export const RankedResultBaseFragmentDoc = gql`
    fragment RankedResultBaseFragment on RankedResult {
  id
  versionName
  versionType
  competitionEventId
  maxEntryLockedAt
  results
}
    `;
export const MarkScoresheetFragmentDoc = gql`
    fragment MarkScoresheetFragment on MarkScoresheet {
  completedAt
  submittedAt
  openedAt
  judge {
    id
    name
  }
  marks
}
    `;
export const TallyScoresheetFragmentDoc = gql`
    fragment TallyScoresheetFragment on TallyScoresheet {
  tally
}
    `;
export const ScoresheetBaseFragmentDoc = gql`
    fragment ScoresheetBaseFragment on Scoresheet {
  id
  judge {
    id
  }
  rulesId
  judgeType
  competitionEventId
  createdAt
  updatedAt
  excludedAt
  options
  ...MarkScoresheetFragment
  ...TallyScoresheetFragment
}
    ${MarkScoresheetFragmentDoc}
${TallyScoresheetFragmentDoc}`;
export const RegisterUserDocument = gql`
    mutation RegisterUser($name: String) {
  registerUser(name: $name)
}
    `;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRegisterUserMutation({
 *   variables: {
 *     name: // value for 'name'
 *   },
 * });
 */
export function useRegisterUserMutation(options: VueApolloComposable.UseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, options);
}
export type RegisterUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<RegisterUserMutation, RegisterUserMutationVariables>;
export const RequestStreamShareDocument = gql`
    mutation RequestStreamShare($deviceId: ID!) {
  requestDeviceStreamShare(deviceId: $deviceId) {
    ...DeviceStreamShareFragment
  }
}
    ${DeviceStreamShareFragmentDoc}`;

/**
 * __useRequestStreamShareMutation__
 *
 * To run a mutation, you first call `useRequestStreamShareMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRequestStreamShareMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRequestStreamShareMutation({
 *   variables: {
 *     deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useRequestStreamShareMutation(options: VueApolloComposable.UseMutationOptions<RequestStreamShareMutation, RequestStreamShareMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<RequestStreamShareMutation, RequestStreamShareMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<RequestStreamShareMutation, RequestStreamShareMutationVariables>(RequestStreamShareDocument, options);
}
export type RequestStreamShareMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<RequestStreamShareMutation, RequestStreamShareMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($name: String) {
  updateUser(name: $name) {
    id
    name
  }
}
    `;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateUserMutation({
 *   variables: {
 *     name: // value for 'name'
 *   },
 * });
 */
export function useUpdateUserMutation(options: VueApolloComposable.UseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
}
export type UpdateUserMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateUserMutation, UpdateUserMutationVariables>;
export const GroupEntriesDocument = gql`
    query GroupEntries($groupId: ID!) {
  group(groupId: $groupId) {
    id
    entries {
      ...EntryFragment
    }
  }
}
    ${EntryFragmentDoc}`;

/**
 * __useGroupEntriesQuery__
 *
 * To run a query within a Vue component, call `useGroupEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupEntriesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGroupEntriesQuery({
 *   groupId: // value for 'groupId'
 * });
 */
export function useGroupEntriesQuery(variables: GroupEntriesQueryVariables | VueCompositionApi.Ref<GroupEntriesQueryVariables> | ReactiveFunction<GroupEntriesQueryVariables>, options: VueApolloComposable.UseQueryOptions<GroupEntriesQuery, GroupEntriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GroupEntriesQuery, GroupEntriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GroupEntriesQuery, GroupEntriesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GroupEntriesQuery, GroupEntriesQueryVariables>(GroupEntriesDocument, variables, options);
}
export function useGroupEntriesLazyQuery(variables?: GroupEntriesQueryVariables | VueCompositionApi.Ref<GroupEntriesQueryVariables> | ReactiveFunction<GroupEntriesQueryVariables>, options: VueApolloComposable.UseQueryOptions<GroupEntriesQuery, GroupEntriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GroupEntriesQuery, GroupEntriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GroupEntriesQuery, GroupEntriesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GroupEntriesQuery, GroupEntriesQueryVariables>(GroupEntriesDocument, variables, options);
}
export type GroupEntriesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GroupEntriesQuery, GroupEntriesQueryVariables>;
export const GroupInfoDocument = gql`
    query GroupInfo($groupId: ID!) {
  group(groupId: $groupId) {
    id
    name
    currentHeat
  }
}
    `;

/**
 * __useGroupInfoQuery__
 *
 * To run a query within a Vue component, call `useGroupInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupInfoQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGroupInfoQuery({
 *   groupId: // value for 'groupId'
 * });
 */
export function useGroupInfoQuery(variables: GroupInfoQueryVariables | VueCompositionApi.Ref<GroupInfoQueryVariables> | ReactiveFunction<GroupInfoQueryVariables>, options: VueApolloComposable.UseQueryOptions<GroupInfoQuery, GroupInfoQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GroupInfoQuery, GroupInfoQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GroupInfoQuery, GroupInfoQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GroupInfoQuery, GroupInfoQueryVariables>(GroupInfoDocument, variables, options);
}
export function useGroupInfoLazyQuery(variables?: GroupInfoQueryVariables | VueCompositionApi.Ref<GroupInfoQueryVariables> | ReactiveFunction<GroupInfoQueryVariables>, options: VueApolloComposable.UseQueryOptions<GroupInfoQuery, GroupInfoQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GroupInfoQuery, GroupInfoQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GroupInfoQuery, GroupInfoQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GroupInfoQuery, GroupInfoQueryVariables>(GroupInfoDocument, variables, options);
}
export type GroupInfoQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GroupInfoQuery, GroupInfoQueryVariables>;
export const GroupsDocument = gql`
    query Groups {
  groups {
    id
    name
    completedAt
  }
}
    `;

/**
 * __useGroupsQuery__
 *
 * To run a query within a Vue component, call `useGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useGroupsQuery();
 */
export function useGroupsQuery(options: VueApolloComposable.UseQueryOptions<GroupsQuery, GroupsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GroupsQuery, GroupsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GroupsQuery, GroupsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, {}, options);
}
export function useGroupsLazyQuery(options: VueApolloComposable.UseQueryOptions<GroupsQuery, GroupsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<GroupsQuery, GroupsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<GroupsQuery, GroupsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, {}, options);
}
export type GroupsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<GroupsQuery, GroupsQueryVariables>;
export const HeatEntriesDocument = gql`
    query HeatEntries($groupId: ID!, $heat: Int!) {
  group(groupId: $groupId) {
    id
    entriesByHeat(heat: $heat) {
      ...EntryFragment
    }
  }
}
    ${EntryFragmentDoc}`;

/**
 * __useHeatEntriesQuery__
 *
 * To run a query within a Vue component, call `useHeatEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeatEntriesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useHeatEntriesQuery({
 *   groupId: // value for 'groupId'
 *   heat: // value for 'heat'
 * });
 */
export function useHeatEntriesQuery(variables: HeatEntriesQueryVariables | VueCompositionApi.Ref<HeatEntriesQueryVariables> | ReactiveFunction<HeatEntriesQueryVariables>, options: VueApolloComposable.UseQueryOptions<HeatEntriesQuery, HeatEntriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<HeatEntriesQuery, HeatEntriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<HeatEntriesQuery, HeatEntriesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<HeatEntriesQuery, HeatEntriesQueryVariables>(HeatEntriesDocument, variables, options);
}
export function useHeatEntriesLazyQuery(variables?: HeatEntriesQueryVariables | VueCompositionApi.Ref<HeatEntriesQueryVariables> | ReactiveFunction<HeatEntriesQueryVariables>, options: VueApolloComposable.UseQueryOptions<HeatEntriesQuery, HeatEntriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<HeatEntriesQuery, HeatEntriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<HeatEntriesQuery, HeatEntriesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<HeatEntriesQuery, HeatEntriesQueryVariables>(HeatEntriesDocument, variables, options);
}
export type HeatEntriesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<HeatEntriesQuery, HeatEntriesQueryVariables>;
export const HeatEntriesScoresheetsDocument = gql`
    query HeatEntriesScoresheets($groupId: ID!, $heat: Int!) {
  group(groupId: $groupId) {
    id
    entriesByHeat(heat: $heat) {
      ...EntryFragment
      scoresheets {
        ...ScoresheetBaseFragment
        ...MarkScoresheetFragment
        ...TallyScoresheetFragment
      }
    }
  }
}
    ${EntryFragmentDoc}
${ScoresheetBaseFragmentDoc}
${MarkScoresheetFragmentDoc}
${TallyScoresheetFragmentDoc}`;

/**
 * __useHeatEntriesScoresheetsQuery__
 *
 * To run a query within a Vue component, call `useHeatEntriesScoresheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeatEntriesScoresheetsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useHeatEntriesScoresheetsQuery({
 *   groupId: // value for 'groupId'
 *   heat: // value for 'heat'
 * });
 */
export function useHeatEntriesScoresheetsQuery(variables: HeatEntriesScoresheetsQueryVariables | VueCompositionApi.Ref<HeatEntriesScoresheetsQueryVariables> | ReactiveFunction<HeatEntriesScoresheetsQueryVariables>, options: VueApolloComposable.UseQueryOptions<HeatEntriesScoresheetsQuery, HeatEntriesScoresheetsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<HeatEntriesScoresheetsQuery, HeatEntriesScoresheetsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<HeatEntriesScoresheetsQuery, HeatEntriesScoresheetsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<HeatEntriesScoresheetsQuery, HeatEntriesScoresheetsQueryVariables>(HeatEntriesScoresheetsDocument, variables, options);
}
export function useHeatEntriesScoresheetsLazyQuery(variables?: HeatEntriesScoresheetsQueryVariables | VueCompositionApi.Ref<HeatEntriesScoresheetsQueryVariables> | ReactiveFunction<HeatEntriesScoresheetsQueryVariables>, options: VueApolloComposable.UseQueryOptions<HeatEntriesScoresheetsQuery, HeatEntriesScoresheetsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<HeatEntriesScoresheetsQuery, HeatEntriesScoresheetsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<HeatEntriesScoresheetsQuery, HeatEntriesScoresheetsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<HeatEntriesScoresheetsQuery, HeatEntriesScoresheetsQueryVariables>(HeatEntriesScoresheetsDocument, variables, options);
}
export type HeatEntriesScoresheetsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<HeatEntriesScoresheetsQuery, HeatEntriesScoresheetsQueryVariables>;
export const LeaderboardDocument = gql`
    query Leaderboard($groupId: ID!, $limit: Int, $maxVisibility: ResultVersionType) {
  group(groupId: $groupId) {
    id
    name
    currentHeat
    categories {
      id
      name
      type
      participants {
        ...AthleteFragment
        ...TeamFragment
      }
      rankedResults(limit: $limit, maxVisibility: $maxVisibility) {
        ...RankedResultBaseFragment
      }
    }
  }
}
    ${AthleteFragmentDoc}
${TeamFragmentDoc}
${RankedResultBaseFragmentDoc}`;

/**
 * __useLeaderboardQuery__
 *
 * To run a query within a Vue component, call `useLeaderboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeaderboardQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useLeaderboardQuery({
 *   groupId: // value for 'groupId'
 *   limit: // value for 'limit'
 *   maxVisibility: // value for 'maxVisibility'
 * });
 */
export function useLeaderboardQuery(variables: LeaderboardQueryVariables | VueCompositionApi.Ref<LeaderboardQueryVariables> | ReactiveFunction<LeaderboardQueryVariables>, options: VueApolloComposable.UseQueryOptions<LeaderboardQuery, LeaderboardQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<LeaderboardQuery, LeaderboardQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<LeaderboardQuery, LeaderboardQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<LeaderboardQuery, LeaderboardQueryVariables>(LeaderboardDocument, variables, options);
}
export function useLeaderboardLazyQuery(variables?: LeaderboardQueryVariables | VueCompositionApi.Ref<LeaderboardQueryVariables> | ReactiveFunction<LeaderboardQueryVariables>, options: VueApolloComposable.UseQueryOptions<LeaderboardQuery, LeaderboardQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<LeaderboardQuery, LeaderboardQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<LeaderboardQuery, LeaderboardQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<LeaderboardQuery, LeaderboardQueryVariables>(LeaderboardDocument, variables, options);
}
export type LeaderboardQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<LeaderboardQuery, LeaderboardQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ... on Device {
      id
      name
    }
    ... on User {
      id
      name
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a Vue component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMeQuery();
 */
export function useMeQuery(options: VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<MeQuery, MeQueryVariables>(MeDocument, {}, options);
}
export function useMeLazyQuery(options: VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, {}, options);
}
export type MeQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MeQuery, MeQueryVariables>;
export const UserStreamSharesDocument = gql`
    query UserStreamShares {
  me {
    ... on User {
      id
      streamShares {
        ...DeviceStreamShareFragment
      }
    }
    ... on Device {
      id
    }
  }
}
    ${DeviceStreamShareFragmentDoc}`;

/**
 * __useUserStreamSharesQuery__
 *
 * To run a query within a Vue component, call `useUserStreamSharesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserStreamSharesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUserStreamSharesQuery();
 */
export function useUserStreamSharesQuery(options: VueApolloComposable.UseQueryOptions<UserStreamSharesQuery, UserStreamSharesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UserStreamSharesQuery, UserStreamSharesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UserStreamSharesQuery, UserStreamSharesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<UserStreamSharesQuery, UserStreamSharesQueryVariables>(UserStreamSharesDocument, {}, options);
}
export function useUserStreamSharesLazyQuery(options: VueApolloComposable.UseQueryOptions<UserStreamSharesQuery, UserStreamSharesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UserStreamSharesQuery, UserStreamSharesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UserStreamSharesQuery, UserStreamSharesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<UserStreamSharesQuery, UserStreamSharesQueryVariables>(UserStreamSharesDocument, {}, options);
}
export type UserStreamSharesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<UserStreamSharesQuery, UserStreamSharesQueryVariables>;
export const DeviceStreamMarkAddedDocument = gql`
    subscription DeviceStreamMarkAdded($deviceIds: [ID!]!) {
  deviceStreamMarkAdded(deviceIds: $deviceIds) {
    sequence
    tally
    mark
    info {
      competitionEventId
      rulesId
      judgeType
    }
    device {
      id
    }
  }
}
    `;

/**
 * __useDeviceStreamMarkAddedSubscription__
 *
 * To run a query within a Vue component, call `useDeviceStreamMarkAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDeviceStreamMarkAddedSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useDeviceStreamMarkAddedSubscription({
 *   deviceIds: // value for 'deviceIds'
 * });
 */
export function useDeviceStreamMarkAddedSubscription(variables: DeviceStreamMarkAddedSubscriptionVariables | VueCompositionApi.Ref<DeviceStreamMarkAddedSubscriptionVariables> | ReactiveFunction<DeviceStreamMarkAddedSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<DeviceStreamMarkAddedSubscription, DeviceStreamMarkAddedSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<DeviceStreamMarkAddedSubscription, DeviceStreamMarkAddedSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<DeviceStreamMarkAddedSubscription, DeviceStreamMarkAddedSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<DeviceStreamMarkAddedSubscription, DeviceStreamMarkAddedSubscriptionVariables>(DeviceStreamMarkAddedDocument, variables, options);
}
export type DeviceStreamMarkAddedSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<DeviceStreamMarkAddedSubscription, DeviceStreamMarkAddedSubscriptionVariables>;
export const HeatChangedDocument = gql`
    subscription HeatChanged($groupId: ID!) {
  heatChanged(groupId: $groupId)
}
    `;

/**
 * __useHeatChangedSubscription__
 *
 * To run a query within a Vue component, call `useHeatChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useHeatChangedSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useHeatChangedSubscription({
 *   groupId: // value for 'groupId'
 * });
 */
export function useHeatChangedSubscription(variables: HeatChangedSubscriptionVariables | VueCompositionApi.Ref<HeatChangedSubscriptionVariables> | ReactiveFunction<HeatChangedSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<HeatChangedSubscription, HeatChangedSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<HeatChangedSubscription, HeatChangedSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<HeatChangedSubscription, HeatChangedSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<HeatChangedSubscription, HeatChangedSubscriptionVariables>(HeatChangedDocument, variables, options);
}
export type HeatChangedSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<HeatChangedSubscription, HeatChangedSubscriptionVariables>;
export const ScoresheetChangedDocument = gql`
    subscription ScoresheetChanged($entryIds: [ID!]!) {
  scoresheetChanged(entryIds: $entryIds)
}
    `;

/**
 * __useScoresheetChangedSubscription__
 *
 * To run a query within a Vue component, call `useScoresheetChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useScoresheetChangedSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useScoresheetChangedSubscription({
 *   entryIds: // value for 'entryIds'
 * });
 */
export function useScoresheetChangedSubscription(variables: ScoresheetChangedSubscriptionVariables | VueCompositionApi.Ref<ScoresheetChangedSubscriptionVariables> | ReactiveFunction<ScoresheetChangedSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<ScoresheetChangedSubscription, ScoresheetChangedSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<ScoresheetChangedSubscription, ScoresheetChangedSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<ScoresheetChangedSubscription, ScoresheetChangedSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<ScoresheetChangedSubscription, ScoresheetChangedSubscriptionVariables>(ScoresheetChangedDocument, variables, options);
}
export type ScoresheetChangedSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<ScoresheetChangedSubscription, ScoresheetChangedSubscriptionVariables>;
export const StreamMarkAddedDocument = gql`
    subscription StreamMarkAdded($scoresheetIds: [ID!]!) {
  streamMarkAdded(scoresheetIds: $scoresheetIds) {
    sequence
    tally
    mark
    scoresheet {
      id
    }
  }
}
    `;

/**
 * __useStreamMarkAddedSubscription__
 *
 * To run a query within a Vue component, call `useStreamMarkAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useStreamMarkAddedSubscription` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the subscription
 * @param options that will be passed into the subscription, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/subscription.html#options;
 *
 * @example
 * const { result, loading, error } = useStreamMarkAddedSubscription({
 *   scoresheetIds: // value for 'scoresheetIds'
 * });
 */
export function useStreamMarkAddedSubscription(variables: StreamMarkAddedSubscriptionVariables | VueCompositionApi.Ref<StreamMarkAddedSubscriptionVariables> | ReactiveFunction<StreamMarkAddedSubscriptionVariables>, options: VueApolloComposable.UseSubscriptionOptions<StreamMarkAddedSubscription, StreamMarkAddedSubscriptionVariables> | VueCompositionApi.Ref<VueApolloComposable.UseSubscriptionOptions<StreamMarkAddedSubscription, StreamMarkAddedSubscriptionVariables>> | ReactiveFunction<VueApolloComposable.UseSubscriptionOptions<StreamMarkAddedSubscription, StreamMarkAddedSubscriptionVariables>> = {}) {
  return VueApolloComposable.useSubscription<StreamMarkAddedSubscription, StreamMarkAddedSubscriptionVariables>(StreamMarkAddedDocument, variables, options);
}
export type StreamMarkAddedSubscriptionCompositionFunctionResult = VueApolloComposable.UseSubscriptionReturn<StreamMarkAddedSubscription, StreamMarkAddedSubscriptionVariables>;